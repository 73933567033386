import { Pipe, PipeTransform } from '@angular/core';
import { KurzLengthUnitCode, KurzUnitFormattedNumberOptions, KurzUnitService, KurzUnitSystem } from '../../services/kurz-unit.service';

@Pipe({
  name: 'kurzLength'
})
export class KurzLengthPipe implements PipeTransform {

  constructor(private kurzUnitService: KurzUnitService) {}

  transform(value: string | number, lengthUnitCode: KurzLengthUnitCode): string {

    let num: number;

    switch(typeof value) {
      case 'number': num = value as number; break;
      case 'string': num = parseFloat(value); break;
      default: num = value as number;
    }

    const options: KurzUnitFormattedNumberOptions = {explicitUnitSystem: lengthUnitCode};

    return this.kurzUnitService.getFormattedLength(num, options);
  }

}
